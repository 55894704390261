import React, { useEffect, useState } from "react";
import "./App.css";
import { db, auth } from "./FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { Table, Button, Spin, theme } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Navigate } from "react-router-dom";

// Table column definitions
const columns = [
  {
    title: "ファイル名",
    dataIndex: "photopath",
    key: "photopath",
    width: "20%",
    sorter: (a, b) => a.photopath.localeCompare(b.photopath),
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "品種名",
    dataIndex: "cultiverName",
    key: "cultiverName",
    width: "20%",
    sorter: (a, b) => a.cultiverName.localeCompare(b.cultiverName),
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "平均気孔開度",
    dataIndex: "aperture",
    key: "aperture",
    width: "8%",
    sorter: (a, b) => a.aperture - b.aperture,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "気孔の数（個）",
    dataIndex: "size",
    key: "size",
    width: "8%",
    sorter: (a, b) => a.size - b.size,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "撮影場所",
    dataIndex: "location",
    key: "location",
    width: "12%",
    sorter: (a, b) => a.location.localeCompare(b.location),
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "重要度",
    dataIndex: "important",
    key: "important",
    width: "8%",
    sorter: (a, b) => a.important.localeCompare(b.important),
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "自由記入",
    dataIndex: "freeText",
    key: "freeText",
    width: "20%",
    sorter: (a, b) => a.freeText.localeCompare(b.freeText),
    sortDirections: ["ascend", "descend", "ascend"],
  },
];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
};

function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");

  return `${year}${month}${day}_${hours}${minutes}${seconds}`;
}

function Download() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [user, setUser] = useState(null); // Initialize user state as null
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe(); // Cleanup function
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        setLoading(true);
        try {
          const email = user.email;
          const snapshot = await getDocs(collection(db, email));
          setPosts(
            snapshot.docs.map((doc) => ({ key: doc.id, ...doc.data() }))
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [user]);

  const handleSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const downloadCSV = (type) => {
    const data = type === "aperture" ? getData("aperture") : getData("area");
    fetchData(data);
  };

  const downloadJSON = () => {
    const fileName = `${getCurrentDateTime()}.json`;
    const data = new Blob([JSON.stringify(selectedData, null, 2)], {
      type: "application/json",
    });
    const jsonURL = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = jsonURL;
    link.setAttribute("download", fileName);
    link.click();
    URL.revokeObjectURL(jsonURL);
  };

  const getData = (type) => {
    let csvContent =
      type === "aperture"
        ? "photopath,date,cultivername,aperture\n"
        : "photopath,date,cultivername,area\n";
    selectedData.forEach((doc) => {
      if (doc.results) {
        const values = doc.results
          .map((result) =>
            type === "aperture" ? result.aperture : result.area
          )
          .join(",");
        csvContent += `${doc.photopath},${doc.date},${doc.cultiverName},${values}\n`;
      }
    });
    return csvContent;
  };

  const fetchData = (csvContent) => {
    const filename = `StomataStorage_${getCurrentDateTime()}.csv`;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const selectedData = posts.filter((doc) => selectedRowKeys.includes(doc.key));

  return (
    <>
      <div
        style={{ padding: 24, background: colorBgContainer, height: "100%" }}
      >
        {loading && (
          <div className="spin">
            <Spin size="large" tip="Loading" />
          </div>
        )}
        {!loading && (
          <>
            {!user ? (
              <Navigate to={"/"} />
            ) : (
              <>
                <Table
                  columns={columns}
                  dataSource={posts}
                  rowSelection={{
                    selectedRowKeys,
                    onChange: handleSelectChange,
                  }}
                />
                <div className="addButton">
                  <Button
                    size="large"
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={() => downloadCSV("aperture")}
                    disabled={!selectedRowKeys.length}
                  >
                    ダウンロード（CSV_aperture）
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={() => downloadCSV("area")}
                    disabled={!selectedRowKeys.length}
                  >
                    ダウンロード（CSV_area）
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={downloadJSON}
                    disabled={!selectedRowKeys.length}
                  >
                    ダウンロード（JSON_AllData）
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Download;
