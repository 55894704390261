import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./FirebaseConfig.js";
import { Navigate } from "react-router-dom";
import { Spin, QRCode, Table, Tag } from "antd";
import { AndroidFilled } from "@ant-design/icons";

const columns = [
  {
    title: "アプリ名",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "バージョン",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "更新日",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "アプリQRコード",
    dataIndex: "file",
    key: "file",
  },
  {
    title: "アプリ",
    dataIndex: "APP",
    key: "APP",
  },
  {
    title: "品種",
    key: "tags",
    dataIndex: "tags",
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          let color = tag.length > 10 ? "geekblue" : "green";
          if (tag === "loser") {
            color = "volcano";
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: "備考",
    dataIndex: "remarks",
    key: "remarks",
  },
];

const data = [
  {
    key: "1",
    name: "StomataScope for TOMATO_V2",
    age: "2.0",
    file: (
      <QRCode
        style={{
          margin: "auto",
          width: "70%",
        }}
        errorLevel="L"
        size={100}
        value="https://drive.google.com/file/d/1y59S_lYUjgNteMfdO9oTX5OBfkJKIX73/view?usp=drive_link"
      />
    ),
    APP: (
      <a
        href="https://drive.google.com/file/d/1y59S_lYUjgNteMfdO9oTX5OBfkJKIX73/view?usp=drive_link"
        target="_blank"
        rel="noreferrer"
        className="webLink"
      >
        <AndroidFilled style={{ fontSize: "40px", color: "#9acd32" }} />
      </a>
    ),
    address: "2023/10/24",
    tags: ["フルティカ"],
    remarks: "検出精度を向上させました",
  },
  {
    key: "2",
    name: "StomataScope for EggPlant",
    age: "1.0",
    file: (
      <QRCode
        style={{
          margin: "auto",
          width: "70%",
        }}
        errorLevel="L"
        size={100}
        value="https://drive.google.com/file/d/1vIyTbzgpi7gHccd0TWFHP1yyu1biUpeP/view?usp=drive_link"
      />
    ),
    APP: (
      <a
        href="https://drive.google.com/file/d/1vIyTbzgpi7gHccd0TWFHP1yyu1biUpeP/view?usp=drive_link"
        target="_blank"
        rel="noreferrer"
        className="webLink"
      >
        <AndroidFilled style={{ fontSize: "40px", color: "#9acd32" }} />
      </a>
    ),
    address: "2023/8/24",
    tags: ["筑陽"],
    remarks: "V1をリリースしました",
  },
  {
    key: "3",
    name: "StomataScope for Strawberry",
    age: "1.0",
    file: (
      <QRCode
        style={{
          margin: "auto",
          width: "70%",
        }}
        errorLevel="L"
        size={100}
        value="https://drive.google.com/file/d/1A_s0VS6eHSDNYZjWjo8ZrcdAXgzmMw00/view?usp=drive_link"
      />
    ),
    APP: (
      <a
        href="https://drive.google.com/file/d/1A_s0VS6eHSDNYZjWjo8ZrcdAXgzmMw00/view?usp=drive_link"
        target="_blank"
        rel="noreferrer"
        className="webLink"
      >
        <AndroidFilled style={{ fontSize: "40px", color: "#9acd32" }} />
      </a>
    ),
    address: "2023/9/21",
    tags: ["紅ほっぺ", "ヨツボシ"],
    remarks: "V1をリリースしました",
  },
  {
    key: "4",
    name: "StomataScope for TOMATO",
    age: "1.0",
    file: (
      <QRCode
        style={{
          margin: "auto",
          width: "70%",
        }}
        errorLevel="L"
        size={100}
        value="https://drive.google.com/file/d/1tdLUelJQz9TiDjkakjPv1RXhJVBhoH0U/view?usp=drive_link"
      />
    ),
    APP: (
      <a
        href="https://drive.google.com/file/d/1tdLUelJQz9TiDjkakjPv1RXhJVBhoH0U/view?usp=drive_link"
        target="_blank"
        rel="noreferrer"
        className="webLink"
      >
        <AndroidFilled style={{ fontSize: "40px", color: "#9acd32" }} />
      </a>
    ),
    address: "2023/6/1",
    tags: ["フルティカ"],
    remarks: "V1をリリースしました",
  },
  {
    key: "5",
    name: "StomataScope for LETTUCE",
    age: "1.0",
    file: (
      <QRCode
        style={{
          margin: "auto",
          width: "70%",
        }}
        errorLevel="L"
        size={100}
        value="https://drive.google.com/file/d/1Ml3iUKjxjqdIAL4a4Avbrgy-8Y0wORbi/view?usp=sharing"
      />
    ),
    APP: (
      <a
        href="https://drive.google.com/file/d/1Ml3iUKjxjqdIAL4a4Avbrgy-8Y0wORbi/view?usp=sharing"
        target="_blank"
        rel="noreferrer"
        className="webLink"
      >
        <AndroidFilled style={{ fontSize: "40px", color: "#9acd32" }} />
      </a>
    ),
    address: "2024/4/23",
    tags: ["オークリーフ", "レッドオーク", "フリルレタス"],
    remarks: "V1をリリースしました",
  },
  {
    key: "6",
    name: "StomataScope for Wheat",
    age: "0.1",
    file: (
      <QRCode
        style={{
          margin: "auto",
          width: "70%",
        }}
        errorLevel="L"
        size={100}
        value="https://drive.google.com/file/d/1JMWeUsq8BR_6HbXh0x3bB5VfKmx8EGMR/view?usp=drive_link"
      />
    ),
    APP: (
      <a
        href="https://drive.google.com/file/d/1JMWeUsq8BR_6HbXh0x3bB5VfKmx8EGMR/view?usp=drive_link"
        target="_blank"
        rel="noreferrer"
        className="webLink"
      >
        <AndroidFilled style={{ fontSize: "40px", color: "#9acd32" }} />
      </a>
    ),
    address: "2024/4/23",
    tags: ["小麦農林61号"],
    remarks: "テストリリース",
  },
  {
    key: "7",
    name: "StomataScope for Cabbage",
    age: "0.1",
    file: (
      <QRCode
        style={{
          margin: "auto",
          width: "70%",
        }}
        errorLevel="L"
        size={100}
        value="https://drive.google.com/file/d/1qjRH6j7UKLVW1puAul2rDzaTUahwACGo/view?usp=drive_link"
      />
    ),
    APP: (
      <a
        href="https://drive.google.com/file/d/1qjRH6j7UKLVW1puAul2rDzaTUahwACGo/view?usp=drive_link"
        target="_blank"
        rel="noreferrer"
        className="webLink"
      >
        <AndroidFilled style={{ fontSize: "40px", color: "#9acd32" }} />
      </a>
    ),
    address: "2024/4/23",
    tags: ["金系201EX"],
    remarks: "テストリリース",
  },{
    key: "8",
    name: "StomataScope for Arabi",
    age: "1.0",
    file: (
      <QRCode
        style={{
          margin: "auto",
          width: "70%",
        }}
        errorLevel="L"
        size={100}
        value="https://drive.google.com/file/d/1bUxeDV9s69Pjyl2H4nirERLoK_T2pGyA/view?usp=drive_link"
      />
    ),
    APP: (
      <a
        href="https://drive.google.com/file/d/1bUxeDV9s69Pjyl2H4nirERLoK_T2pGyA/view?usp=drive_link"
        target="_blank"
        rel="noreferrer"
        className="webLink"
      >
        <AndroidFilled style={{ fontSize: "40px", color: "#9acd32" }} />
      </a>
    ),
    address: "2024/10/22",
    tags: [""],
    remarks: "",
  },
];

const Mypage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="spin">
        <Spin size="large" tip="Loading" />
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div
      className="center"
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        lineHeight: "100px",
      }}
    >
      <Table
        columns={columns}
        dataSource={data}
        style={{ padding: 20 }}
        pagination={{ pageSize: 5 }}
      />
      iOSには対応しておりません。またデバイスやAndroidのバージョンによっては、使用できない場合があります。
    </div>
  );
};

export default Mypage;
